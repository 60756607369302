"use client"
import React from "react";
import MovieCard from "./Card/MovieCard";

export default function Banner({movies}) {
    return (
        <div className="mb-4 md:mb-8">            
            <MovieCard movies={movies} type="lg"
                useNavigation={false}
                pagination={{
                    dynamicBullets: true,
                    clickable:true
                }}
                breakpoints={{
                    1080: {
                        slidesPerView:3,
                    },
                    360: {
                        slidesPerView:2,
                    },
                }}
            />
        </div>
    )
}
